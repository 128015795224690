import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { Banner, mapToPageHeader } from "@components/Banner"
import { SEO, mapToSEO } from "@components/SEO"
import {
  SignupBannerWrapper,
  mapToKiwiVIPVisible,
} from "@components/SignupBanner"

const ErrorPage = (props: any) => {
  const { elements } = props.data.kontentItemErrorPage
  const seo = mapToSEO(elements)
  const kiwiVip = mapToKiwiVIPVisible(elements)
  const metadata = mapToPageHeader(elements)

  const [isMount, setMount] = React.useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return <div>loading...</div>
  }

  return (
    <Layout location={props.location}>
      <SEO {...seo} />
      <Banner {...metadata} isBiggerBanner={false} />
      {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        apiUrl
      }
    }
    kontentItemErrorPage {
      elements {
        name {
          value
        }
        seo__noindex {
          value {
            name
            codename
          }
        }
        seo__nofollow {
          value {
            name
            codename
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        general_page_content__title {
          value
        }
        general_page_content__header_image {
          value {
            url
            description
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            name
            codename
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            name
            codename
          }
        }
        general_page_content__title_position {
          value {
            codename
          }
        }
        url {
          value
        }
      }
    }
  }
`

export default ErrorPage
